import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  authData: null,
  loggedIn: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.authData = action.payload
      state.loggedIn = true
    },
    clearAuthData: (state, action) => {
      state.authData = null
      state.loggedIn = false
    },
  },
})

export const { setAuthData, clearAuthData } = authSlice.actions
export default authSlice.reducer
