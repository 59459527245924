import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    setSideBarVisibility: (state, action) => {
      state.sidebarShow = action.payload
    },
    setSideBarUnfoldable: (state, action) => {
      state.setSideBarUnfoldable = action.payload
    },
  },
})

export const { setSideBarVisibility, setSideBarUnfoldable } = sideBarSlice.actions
export default sideBarSlice.reducer
