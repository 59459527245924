import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASE_URL } from '../../environment'
import { httpClient } from '../../httpClient'

export const getVendors = createAsyncThunk('products/vendors', async () => {
  try {
    const response = await httpClient.get(`${BASE_URL}/vendors`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getCategories = createAsyncThunk('products/categories', async () => {
  try {
    const response = await httpClient.get(`${BASE_URL}/categories`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getSubcategoriesList = createAsyncThunk('products/subcategories', async () => {
  try {
    const response = await httpClient.get(
      `${BASE_URL}/categories/subcategories/e2ef99db-b889-4d6c-97e6-65d3785f3597`,
    )
    return response.data
  } catch (error) {
    return error
  }
})

export const addProduct = createAsyncThunk('products/addProduct', async (data) => {
  try {
    const response = await httpClient.post(`${BASE_URL}/vendor/products`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const editProduct = createAsyncThunk('products/editProduct', async ({ editProduct }) => {
  try {
    const response = await httpClient.put(
      `${BASE_URL}/vendor/products/${editProduct.id}`,
      editProduct.data,
    )
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteProductImage = createAsyncThunk('products/deleteProductImage', async (data) => {
  try {
    const response = await httpClient.delete(
      `${BASE_URL}/vendor/products/images/${data}`,
      editProduct.data,
    )
    return response.data
  } catch (error) {
    return error
  }
})

export const getProducts = createAsyncThunk('products/allProducts', async ({ nextToken }) => {
  try {
    const response = await httpClient.get(`${BASE_URL}/vendor/products/?token=${nextToken}`)
    return response.data
  } catch (error) {
    return error
  }
})

const initialState = {
  addProductActive: false,
  showAddProductButton: true,
  vendorList: [],
  productAdded: false,
  productAddInProgress: false,
  productEditInProgress: false,
  subcategories: [],
  categories: [],
  productToken: null,
  products: [],
  getProductsInProgress: false,
  productToAdd: {
    name: null,
    categoryId: null,
    manufacturer: null,
    subcategoryId: null,
    description: null,
    image: null,
    additionalFieldsArray: [],
  },
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setAddProductActive: (state, action) => {
      state.addProductActive = action.payload
      state.showAddProductButton = !action.payload
    },
    updateProductDetail: (state, action) => {
      if (action.payload.type === 'categoryId') {
        state.productToAdd.category = action.payload.value
        state.productToAdd = {
          ...state.productToAdd,
          categoryId: action.payload.value,
          additionalFieldsArray: action.payload.additionalFieldsArray,
        }
      } else {
        state.productToAdd[action.payload.type] = action.payload.value
      }
    },
    updateProductAdditionalFields: (state, action) => {
      state.productToAdd.additionalFieldsArray = state.productToAdd.additionalFieldsArray.map(
        (additionalField) => {
          if (additionalField.field === action.payload.field) {
            return {
              ...additionalField,
              value: action.payload.value,
            }
          }
          return additionalField
        },
      )
    },
    removeProductFromState: (state, action) => {
      state.products = state.products.filter((product) => product.id !== action.payload.id)
    },
    resetEditProduct: (state) => {
      state.productToAdd = {
        name: null,
        categoryId: null,
        manufacturer: null,
        subcategoryId: null,
        description: null,
        image: null,
        additionalFieldsArray: [],
      }
    },
    resetState: (state) => {
      state.addProductActive = false
      state.showAddProductButton = true
      state.vendorList = []
      state.productAdded = false
      state.productAddInProgress = false
      state.subcategories = []
      state.categories = []
      state.productToken = null
      state.products = []
      state.getProductsInProgress = false
      state.productToAdd = {
        name: null,
        categoryId: null,
        manufacturer: null,
        subcategoryId: null,
        description: null,
        image: null,
        additionalFieldsArray: [],
      }
    },
    updateProductAdded: (state) => {
      state.productAdded = false
    },
    addEditProduct: (state, action) => {
      state.productToAdd = action.payload
    },
  },
  extraReducers: {
    [getVendors.fulfilled]: (state, action) => {
      state.vendorList = action.payload
    },
    [getSubcategoriesList.fulfilled]: (state, action) => {
      state.subcategories = action.payload
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload
    },
    [addProduct.pending]: (state) => {
      state.productAddInProgress = true
    },
    [addProduct.fulfilled]: (state) => {
      state.productAddInProgress = false
      state.productAdded = true
      state.productToAdd = {
        name: null,
        categoryId: null,
        manufacturer: null,
        subcategoryId: null,
        description: null,
        image: null,
        additionalFieldsArray: [],
      }
    },
    [addProduct.rejected]: (state) => {
      state.productAddInProgress = false
    },
    [editProduct.pending]: (state) => {
      state.productEditInProgress = true
    },
    [editProduct.fulfilled]: (state) => {
      state.productEditInProgress = false
      state.productAdded = true
      state.productToAdd = {
        name: null,
        categoryId: null,
        manufacturer: null,
        subcategoryId: null,
        description: null,
        image: null,
        additionalFieldsArray: [],
      }
    },
    [editProduct.rejected]: (state) => {
      state.productEditInProgress = false
    },
    [getProducts.pending]: (state, action) => {
      state.getProductsInProgress = true
    },
    [getProducts.rejected]: (state, action) => {
      state.getProductsInProgress = false
    },
    [getProducts.fulfilled]: (state, action) => {
      state.products = action.payload.data
      state.productToken = action.payload.nextToken
      state.getProductsInProgress = false
    },
  },
})

export const {
  setAddProductActive,
  updateProductDetail,
  updateProductAdditionalFields,
  removeProductFromState,
  resetState,
  updateProductAdded,
  addEditProduct,
  resetEditProduct,
} = productsSlice.actions
export default productsSlice.reducer
