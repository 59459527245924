import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import persistStore from 'redux-persist/es/persistStore'
import sideBarReducer from './state/sideBarReducer'
import productsReducer from './views/products/state'
import authReducer from './views/pages/login/loginSlice'
import profileSlice from './views/profile/profileSlice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'profile'],
}

const reducers = combineReducers({
  sideBar: sideBarReducer,
  products: productsReducer,
  auth: authReducer,
  profile: profileSlice,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(store)
