import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../../environment'
import { httpClient } from '../../httpClient'

export const fetchProfile = createAsyncThunk('vendor/fetchProfile', () => {
  return httpClient
    .get(`${BASE_URL}/profile`)
    .then((response) => response.data)
    .catch((error) => error)
})

const initialState = {
  profile: {
    data: {},
    error: {},
    status: 'idle',
  },
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProfile.pending]: (state) => {
      state.profile = {
        status: 'loading',
        data: {},
        error: {},
      }
    },
    [fetchProfile.fulfilled]: (state, action) => {
      state.profile = {
        status: 'idle',
        data: action.payload,
        error: {},
      }
    },
    [fetchProfile.rejected]: (state, action) => {
      state.profile = {
        status: 'idle',
        data: {},
        error: action.payload,
      }
    },
  },
})

export const { logOut } = profileSlice.actions
export default profileSlice.reducer
